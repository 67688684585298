import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/(forms)/loginForm/LoginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/(forms)/registerForm/RegisterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannerSection"] */ "/vercel/path0/src/components/bannerSection/BannerSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/clients/clients.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/featuredSection/featuredSection.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sectionHeading/sectionHeading.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/workingOn/workingOn.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/contact/contact.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/contact/contactOptions/CompleteForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/contact/contactOptions/Email.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/contact/form/Form.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/hero/hero.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/spinner/spinner.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/formSection/formSection.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/contact/contactOptions/contactOptions.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/hero/HeroCta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/hero/HeroLogin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/sections/hero/HeroUsers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/register/register.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/nonReusableComponents/sections/login/login.module.css");
